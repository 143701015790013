import {
  Button,
  Flex,
  SimpleGrid,
  Stack,
  Tabs,
  Text,
} from "@flpstudio/design-system";
import { useState } from "react";

import { Skeleton } from "@/components/atoms/Loading/Skeleton";
import { CardCheckbox } from "@/components/molecules/CardCheckbox/CardCheckbox";
import { useAllSoftwareCategories } from "@/hooks/use-documents";
import { useSoftware, useSoftwareMutation } from "@/hooks/use-software";
import {
  Amplitude,
  ClientOwnProfileEvent,
  ExpertOwnProfileEvent,
} from "@/third-party/amplitude";
import type { Software } from "types";
import { normalizeCategories } from "./util";

interface Props {
  for: "client" | "expert";
  onClose: () => void;
  checked?: Software["id"][];
}

export function SoftwareForm(props: Props) {
  const { data: softwareList, isLoading: isLoadingSoftwareList } =
    useSoftware();

  const { data: categories, isLoading: isLoadingCategories } =
    useAllSoftwareCategories();

  const [checked, setChecked] = useState(props.checked ?? []);

  const { mutateAsync } = useSoftwareMutation();

  if (isLoadingSoftwareList || isLoadingCategories) {
    return <Skeleton />;
  }

  if (!softwareList || !categories) {
    return null;
  }

  const popularCategory = { name: "Most Popular", id: "popular" };
  const popularSoftware = softwareList
    .filter((software) => software.popular)
    .map((software) => ({
      ...software,
      categoryId: popularCategory.id,
    }));

  const categoriesNormalized = [
    { name: popularCategory.name, idSet: new Set([popularCategory.id]) },
    ...normalizeCategories(categories),
  ];

  const subtitle =
    props.for === "expert"
      ? "Please let us know your ability to provide guidance to GuideStack clients on each of the following SaaS applications."
      : "This information will help experts understand your IT setup and provide guidance.";

  return (
    <form name="userSoftware" className="h-full">
      <Stack className="h-full">
        <Flex className="flex-col">
          <Text className="text-[color:--mantine-color-gray-7]">
            {subtitle}
          </Text>
          <Tabs withUnderline={false} defaultValue={popularCategory.name}>
            <Tabs.List className="mb-4 gap-y-0">
              {categoriesNormalized.map((category) => (
                <Tabs.Tab key={category.name} value={category.name}>
                  {category.name}
                </Tabs.Tab>
              ))}
            </Tabs.List>
            {categoriesNormalized.map((category) => (
              <Tabs.Panel key={category.name} value={category.name}>
                <SimpleGrid
                  cols={{ base: 1, lg: 2 }}
                  component="ul"
                  className="m-0 list-none p-0"
                >
                  {[...softwareList, ...popularSoftware]
                    .filter((software) =>
                      category.idSet.has(software.categoryId),
                    )
                    .map((software) => (
                      <li key={software.id}>
                        <CardCheckbox
                          checked={checked.includes(software.id)}
                          onChange={(e) => {
                            setChecked((prev) => {
                              if (e.target.checked) {
                                return [...prev, software.id];
                              }
                              return prev.filter((id) => id !== software.id);
                            });
                            mutateAsync({
                              softwareId: software.id,
                              for: props.for,
                              action: e.target.checked ? "put" : "delete",
                            });
                          }}
                          label={
                            <>
                              <img
                                src={software.icon}
                                alt={software.name}
                                className="size-6"
                              />
                              <Text
                                span
                                title={software.name}
                                className="line-clamp-1 overflow-hidden"
                              >
                                {
                                  // manually added the ellipsis becoz the native text-elipsis
                                  // doesn't work well with long non-breaking text like
                                  // '...(Win/Mac/iOS/Android/Chromebook)'
                                  software.name.length > 30
                                    ? `${software.name.slice(0, 30)}...`
                                    : software.name
                                }
                              </Text>
                            </>
                          }
                          value={software.id}
                        />
                      </li>
                    ))}
                </SimpleGrid>
              </Tabs.Panel>
            ))}
          </Tabs>
        </Flex>
        <Flex className="-mb-6 sticky bottom-0 z-10 mt-auto flex-col bg-white pb-6 lg:flex-row-reverse">
          <Button
            onClick={() => {
              props.onClose();
              if (props.for === "expert") {
                Amplitude.track(
                  ExpertOwnProfileEvent.name,
                  ExpertOwnProfileEvent.properties.softwareModalClose,
                );
              }

              if (props.for === "client") {
                Amplitude.track(
                  ClientOwnProfileEvent.name,
                  ClientOwnProfileEvent.properties.softwareModalClose,
                );
              }
            }}
            variant="outline"
          >
            Close
          </Button>
        </Flex>
      </Stack>
    </form>
  );
}
