import { Button } from "@flpstudio/design-system";
import { useEffect, useTransition } from "react";

import { useAvailableAdvisorySessionBooking } from "@/hooks/use-booking";
import { useBookingSearchParams } from "@/hooks/use-page-search-params";
import { formatMoney } from "@/utils/format-money";
import { useDisclosure } from "@mantine/hooks";
import { CalendlyAddBookingDialog } from "../CalendlyAddBookingDialog";
import { AddPaymentMethodDialog } from "./AddPaymentMethodDialog";
import { EnterVoucherDialog } from "./EnterVoucherDialog";
import { MaxBookingsDialog } from "./MaxBookingsDialog";
import { UnpaidBookingDialog } from "./UnpaidBookingDialog";

type Props = {
  expertIdentifier: string;
  /**
   * Initiate booking on when `bookingSession` search param is present
   */
  initiateOnSearchParams?: boolean;
  /**
   * Display "rebook" button instead of the "booking" button
   */
  isRebooking?: boolean;
  onClick?: () => void;
};

export function AdvisorySessionBooking(props: Props) {
  const [_, startTransition] = useTransition();

  const { isStartBooking, clearStartBooking, generateStartBookingLink } =
    useBookingSearchParams();

  const [addPaymentMethodDialogOpened, addPaymentMethodDialogHandlers] =
    useDisclosure();
  const [enterVoucherDialogOpened, enterVoucherDialogHandlers] =
    useDisclosure();
  const [maxBookingsDialogOpened, maxBookingsDialogHandlers] = useDisclosure();
  const [unpaidBookingDialogOpened, unpaidBookingDialogHandlers] =
    useDisclosure();
  const [calendlyAddBookingDialogOpened, calendlyAddBookingDialogHandlers] =
    useDisclosure();

  const { data, refetch } = useAvailableAdvisorySessionBooking({
    identifier: props.expertIdentifier,
  });

  // Auto open calendly dialog if start booking param is present
  useEffect(() => {
    if (
      props.initiateOnSearchParams &&
      isStartBooking &&
      data?.calendlySchedulingUrl &&
      // Free sessions take precedence over existence of payment method
      (data?.availableFreeSessions || data?.paymentAdded)
    ) {
      calendlyAddBookingDialogHandlers.open();
    }
  }, [
    props.initiateOnSearchParams,
    isStartBooking,
    data,
    calendlyAddBookingDialogHandlers.open,
  ]);

  // Render button only if booking data is available
  if (!data) {
    return null;
  }

  const {
    availableFreeSessions,
    unpaidInvoiceLink,
    paymentAdded,
    calendlySchedulingUrl,
    reachedMaxUpcomingSessions,
    price,
    durationMinute,
  } = data;

  const initiateAdvisoryBooking = () => {
    props.onClick?.();

    if (!availableFreeSessions && unpaidInvoiceLink) {
      unpaidBookingDialogHandlers.open();
      return;
    }

    if (!availableFreeSessions && !paymentAdded) {
      addPaymentMethodDialogHandlers.open();
      return;
    }

    if (reachedMaxUpcomingSessions) {
      maxBookingsDialogHandlers.open();
      return;
    }

    calendlyAddBookingDialogHandlers.open();
  };

  return (
    <>
      {props.isRebooking ? (
        <Button variant="outline" onClick={initiateAdvisoryBooking}>
          Book again
        </Button>
      ) : (
        <Button
          className="h-fit px-4 py-2"
          classNames={{ label: "flex-col font-normal" }}
          onClick={initiateAdvisoryBooking}
        >
          <span>Book a session</span>
          {availableFreeSessions ? (
            <span className="text-xs/normal">
              {availableFreeSessions} free credit
              {availableFreeSessions > 1 ? "s" : ""} available
            </span>
          ) : (
            <span className="text-xs/normal">
              {formatMoney({
                amount: price.amount,
                currency: price.currency,
                showDecimal: false,
              })}{" "}
              for {durationMinute} min
            </span>
          )}
        </Button>
      )}
      {!paymentAdded && (
        <>
          <AddPaymentMethodDialog
            opened={addPaymentMethodDialogOpened}
            onUseVoucherCode={() => {
              addPaymentMethodDialogHandlers.close();
              // Wrapping it in startTransition so focus moves to the enterVoucherDialog
              startTransition(enterVoucherDialogHandlers.open);
            }}
            onCancel={addPaymentMethodDialogHandlers.close}
            cancelCallbackUrl={window.location.href}
            successCallbackUrl={generateStartBookingLink(window.location.href)}
          />
          <EnterVoucherDialog
            opened={enterVoucherDialogOpened}
            expertIdentifier={props.expertIdentifier}
            onBack={() => {
              enterVoucherDialogHandlers.close();
              // Wrapping it in startTransition so focus moves to the addPaymentMethodDialog
              startTransition(addPaymentMethodDialogHandlers.open);
            }}
            onClose={enterVoucherDialogHandlers.close}
            onContinue={() => {
              enterVoucherDialogHandlers.close();
              initiateAdvisoryBooking();
            }}
          />
        </>
      )}
      {reachedMaxUpcomingSessions && (
        <MaxBookingsDialog
          opened={maxBookingsDialogOpened}
          onCancel={maxBookingsDialogHandlers.close}
        />
      )}
      {unpaidInvoiceLink && (
        <UnpaidBookingDialog
          invoiceLink={unpaidInvoiceLink}
          opened={unpaidBookingDialogOpened}
          onCancel={unpaidBookingDialogHandlers.close}
        />
      )}
      <CalendlyAddBookingDialog
        expertIdentifier={props.expertIdentifier}
        calendlySchedulingUrl={calendlySchedulingUrl}
        bookingType="ADVISORY_BOOKING"
        opened={calendlyAddBookingDialogOpened}
        onConfirm={refetch}
        onClose={() => {
          clearStartBooking();
          calendlyAddBookingDialogHandlers.close();
        }}
        isVoucherCodeUsed={!!availableFreeSessions}
      />
    </>
  );
}
