import {
  Anchor,
  Button,
  Container,
  Flex,
  Grid,
  Modal,
  Paper,
  Stack,
  Stepper,
  Text,
  Title,
} from "@flpstudio/design-system";
import { useState } from "react";
import { Link, Navigate } from "react-router";

import { Logo } from "@/components/atoms/Logo/Logo";
import {
  CLIENT_PROFILE_FORM_ID,
  ClientProfileForm,
} from "@/components/organisms/ClientOnboarding/ClientProfileForm";
import { CompanyInfoForm } from "@/components/organisms/ClientOnboarding/CompanyInfoForm";
import { ITSetupForm } from "@/components/organisms/ClientOnboarding/ITSetupForm";
import { useAuth } from "@/hooks/use-auth";
import { useScreenSize } from "@/hooks/use-screen-size";
import { Amplitude, ClientOnboardingEvent } from "@/third-party/amplitude";
import { paths } from "~/paths";

export function ClientOnboarding() {
  const { user } = useAuth();
  const [step, setStep] = useState(1);
  const { isLg } = useScreenSize();

  if (user?.expertProfile) {
    return <Navigate to={paths.root} />;
  }

  const onNext = () => {
    setStep(step + 1);
  };

  const onBack = () => {
    setStep(step - 1);
  };

  return (
    <>
      <Container>
        <header className="flex items-center justify-between">
          <Logo />
          <Anchor
            component={Link}
            onClick={() => {
              Amplitude.track(
                ClientOnboardingEvent.name,
                ClientOnboardingEvent.properties.skipOnboarding,
              );
            }}
            to={paths.root}
            className="text-[color:--mantine-color-gray-5]"
          >
            Skip onboarding
          </Anchor>
        </header>
        <Grid component="main" className="mt-10">
          <Grid.Col span={{ lg: 2 }} className="hidden lg:block">
            <Stepper
              active={step - 1}
              orientation={isLg ? "vertical" : "horizontal"}
              className="mt-6"
            >
              <Stepper.Step label="Step 1" description="Your profile" />
              <Stepper.Step label="Step 2" description="Company info" />
              <Stepper.Step label="Step 3" description="IT setup" />
            </Stepper>
          </Grid.Col>
          <Grid.Col span={{ lg: 7 }}>
            <Paper className="-mx-6 my-0 lg:m-0">
              {step === 1 && (
                <>
                  <Title className="mb-4 text-xl">Your profile</Title>
                  <ClientProfileForm
                    onSubmit={onNext}
                    actionButtonGroup={
                      <Flex className="flex-col lg:flex-row-reverse">
                        <Button form={CLIENT_PROFILE_FORM_ID} type="submit">
                          Next
                        </Button>
                      </Flex>
                    }
                  />
                </>
              )}
              {step === 2 && (
                <>
                  <Title className="mb-4 text-xl">Company info</Title>
                  <CompanyInfoForm
                    onSubmit={onNext}
                    actionButtonGroup={
                      <Flex className="flex-col bg-white lg:flex-row-reverse lg:justify-between">
                        <Button type="submit">Next</Button>
                        <Button
                          variant="transparent"
                          onClick={onBack}
                          className="ps-0 pe-0"
                        >
                          Back
                        </Button>
                      </Flex>
                    }
                  />
                </>
              )}
              {step === 3 && (
                <>
                  <Title className="mb-4 text-xl">IT setup</Title>
                  <ITSetupForm
                    onSubmit={onNext}
                    actionButtonGroup={
                      <Flex className="flex-col bg-white lg:flex-row-reverse lg:justify-between">
                        <Button type="submit">Submit</Button>
                        <Button
                          variant="transparent"
                          onClick={onBack}
                          className="ps-0 pe-0"
                        >
                          Back
                        </Button>
                      </Flex>
                    }
                  />
                </>
              )}
            </Paper>
          </Grid.Col>
          <Grid.Col span={{ lg: 3 }}>
            <Paper component="aside" className="-m-6 p-6 lg:m-0">
              <Text className="font-semibold">
                Why does GuideStack collect this information?
              </Text>
              <p className="text-[color:--mantine-color-gray-7]">
                This information will help us recommend IT guides and experts
                most relevant for you.
              </p>
              <p className="text-[color:--mantine-color-gray-7]">
                It will be visible only to you and any experts you directly book
                consultations with.
              </p>
            </Paper>
          </Grid.Col>
        </Grid>
      </Container>
      <Modal
        opened={step > 3}
        onClose={() => {
          /* noop */
        }}
        title="Great! You’ve completed your assessment"
        size="md"
      >
        <Stack>
          <Text className="text-[color:--mantine-color-gray-7]">
            Now, we will recommend resources and IT experts who can provide
            consulting sessions tailored to your company’s needs.
          </Text>
          <Flex className="flex flex-col lg:flex-row-reverse lg:justify-between">
            <Button
              onClick={() => {
                Amplitude.track(
                  ClientOnboardingEvent.name,
                  ClientOnboardingEvent.properties.completeGoDashboard,
                );
              }}
              component={Link}
              to={paths.root}
              className="text-center"
            >
              Go to dashboard
            </Button>
          </Flex>
        </Stack>
      </Modal>
    </>
  );
}
