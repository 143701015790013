import type { Variant } from "@amplitude/experiment-node-server";
import { useOutletContext } from "react-router";

type FeatureFlagContext = {
  featureFlags: Record<string, Variant>;
};

export function useFeatureFlag(flag: string) {
  try {
    const outletContext = useOutletContext<FeatureFlagContext>();
    return outletContext.featureFlags[flag]?.value === "on";
  } catch (_error) {
    return false;
  }
}
