import { faAngleLeft } from "@awesome.me/kit-af809b8b43/icons/classic/regular";
import {
  Button,
  Container,
  Group,
  Loader,
  Modal,
  Text,
} from "@flpstudio/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDisclosure } from "@mantine/hooks";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  type To,
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router";

import {
  EditDocumentForm,
  FORM_ID,
  FORM_INTENT_SAVE,
  FORM_INTENT_SUBMIT,
} from "@/components/organisms/EditDocumentForm/EditDocumentForm";
import { SubmitDocumentDialog } from "@/components/organisms/SubmitDocumentDialog/SubmitDocumentDialog";
import { SubmitFailDialog } from "@/components/organisms/SubmitFailDialog/SubmitFailDialog";
import { SubmitSuccessDialog } from "@/components/organisms/SubmitSuccessDialog/SubmitSuccessDialog";
import { UserMenu } from "@/components/organisms/UserMenu/UserMenu";
import { useAuth } from "@/hooks/use-auth";
import { useAutoTriggerAction } from "@/hooks/use-auto-trigger-action";
import { useDocument } from "@/hooks/use-documents";
import { paths } from "~/paths";

const EditDocument = () => {
  const { user } = useAuth();
  const { documentId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [isEditable, setIsEditable] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [submitModalOpen, submitModalHandlers] = useDisclosure();
  const [submitSuccessModalOpen, submitSuccessModalHandlers] = useDisclosure();
  const [submitFailModalOpen, submitFailModalHandlers] = useDisclosure();
  const saveDraftBtnRef = useRef<HTMLButtonElement>(null);

  const { data: doc, error } = useDocument(documentId);

  const dispatchSave = useCallback(() => {
    saveDraftBtnRef.current?.click();
  }, []);

  useEffect(() => {
    // If the document is not found, redirect to 404
    if (error) {
      navigate(paths.notFound, { replace: true });
    }

    if (!doc || !user) {
      return;
    }

    // Users should not be able to edit other users' drafts
    if (doc.status === "DRAFT" && doc.author?.id !== user.id) {
      navigate(paths.notFound, { replace: true });
      return;
    }

    if (doc.status === "IN_REVIEW") {
      if (user.isAdmin) {
        // Unless user is an admin, users must move their documents to draft
        // before editing them
        setIsEditable(true);
        return;
      }

      navigate(`${paths.profile.documents.review}?id=${doc.id}`, {
        replace: true,
      });
      return;
    }

    if (doc.status === "DECLINED") {
      navigate(`${paths.profile.documents.declined}?id=${doc.id}`, {
        replace: true,
      });
      return;
    }

    if (doc.status === "PUBLISHED") {
      if (user.isAdmin) {
        // Unless user is an admin, users must move their documents to draft
        // before editing them
        setIsEditable(true);
        return;
      }
      navigate(
        generatePath(paths.documentation.viewer, {
          urlSlug: doc.urlTitle,
        }),
      );
      return;
    }

    setIsEditable(true);
  }, [doc, error, user, navigate]);

  const handleBack = () => {
    dispatchSave();
    navigate(
      location.key === "default" ? paths.profile.documents.root : (-1 as To),
    );
  };

  useEffect(() => {
    // Submit on CMD+S or CTRL+S
    const submitOnShortcut = (event: KeyboardEvent) => {
      if (event.key === "s" && (event.metaKey || event.ctrlKey)) {
        event.preventDefault();
        dispatchSave();
      }
    };

    // Submit when the tab is hidden
    const submitOnBlur = () => {
      if (document.visibilityState === "hidden") {
        dispatchSave();
      }
    };

    document.addEventListener("keydown", submitOnShortcut);
    document.addEventListener("visibilitychange", submitOnBlur);

    return () => {
      document.removeEventListener("keydown", submitOnShortcut);
      document.removeEventListener("visibilitychange", submitOnBlur);
    };
  }, [dispatchSave]);

  useAutoTriggerAction(() => {
    dispatchSave();
  });

  if (!doc || !isEditable || !user) {
    return null;
  }

  return (
    <>
      <header className="sticky top-0 z-10 bg-white lg:shadow-paper">
        <nav className="px-6 py-4">
          <Group className="justify-end">
            <Button
              variant="outline"
              leftSection={<FontAwesomeIcon icon={faAngleLeft} />}
              onClick={handleBack}
              className="mr-auto border-none"
            >
              Back
            </Button>
            {isSaving && (
              <Group gap={8} className="hidden lg:flex">
                <Text className="text-[--mantine-color-gray-5]">Saving</Text>
                <Loader size="sm" color="gray.4" />
              </Group>
            )}
            <Button
              variant="outline"
              type="submit"
              value={FORM_INTENT_SAVE}
              ref={saveDraftBtnRef}
              form={FORM_ID}
              className="hidden lg:inline"
            >
              {doc.status === "DRAFT" ? "Save as draft" : "Save edits"}
            </Button>
            {doc.status === "DRAFT" && (
              <Button type="submit" form={FORM_ID} value={FORM_INTENT_SUBMIT}>
                Submit for review
              </Button>
            )}
            <UserMenu className="hidden lg:inline" />
          </Group>
        </nav>
      </header>
      <Container component="main">
        <EditDocumentForm
          onFormStatusChange={({
            isSaving,
            isSavingForSubmitSuccess: isSavingForSumbitSuccess,
          }) => {
            setIsSaving(isSaving);
            if (isSavingForSumbitSuccess) {
              submitModalHandlers.open();
            }
          }}
          name="editor"
          document={doc}
        />
      </Container>
      <Modal
        title="Please confirm your guide submission"
        opened={submitModalOpen}
        onClose={submitModalHandlers.close}
      >
        <SubmitDocumentDialog
          document={doc}
          onSuccess={() => {
            submitModalHandlers.close();
            submitSuccessModalHandlers.open();
          }}
          onFail={() => {
            submitModalHandlers.close();
            submitFailModalHandlers.open();
          }}
          onCancel={() => {
            submitModalHandlers.close();
          }}
        />
      </Modal>
      <Modal
        title="Document submitted and under review"
        opened={submitSuccessModalOpen}
        onClose={() => {
          /* noop */
        }}
      >
        <SubmitSuccessDialog
          onDone={() => navigate(paths.profile.documents.review)}
        />
      </Modal>
      <Modal
        title="Submitting document failed"
        opened={submitFailModalOpen}
        onClose={submitFailModalHandlers.close}
      >
        <SubmitFailDialog
          documentId={doc.id}
          onSuccess={() => {
            submitFailModalHandlers.close();
            submitSuccessModalHandlers.open();
          }}
          onFail={submitFailModalHandlers.open}
          onCancel={() => {
            submitFailModalHandlers.close();
          }}
        />
      </Modal>
    </>
  );
};

export { EditDocument };
