import { clsx } from "clsx/lite";

import LogoIcon from "@/assets/logos/guidestack-icon.svg";
import LogoImage from "@/assets/logos/guidestack.svg";

interface Props {
  className?: string;
}

const Logo = (props: Props) => {
  return (
    <picture className={clsx("max-h-[1.375rem]", props.className)}>
      <source media="(max-width: calc(75em - 1px))" srcSet={LogoIcon} />
      <source media="(min-width: 75em)" srcSet={LogoImage} />
      <img src={LogoIcon} alt="GuideStack" className="max-w-32" />
    </picture>
  );
};

export { Logo };
