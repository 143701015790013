import { Anchor } from "@flpstudio/design-system";
import { clsx } from "clsx/lite";
import type { ReactNode } from "react";
import { Link, generatePath } from "react-router";

import { paths } from "~/paths";

type Props = {
  className?: string;
  urlSlug: string;
  softwareIconSrc: string;
  softwareName: string;
  /**
   * If text needs to be customized, pass it here
   */
  text?: ReactNode;
  onClick?: () => void;
};

export function PublishedDocumentTitleLinkSoftware(props: Props) {
  return (
    <Anchor
      component={Link}
      to={generatePath(paths.documentation.viewer, {
        urlSlug: props.urlSlug,
      })}
      onClick={props.onClick}
      className={clsx("flex items-center gap-2 text-inherit", props.className)}
    >
      <img
        src={props.softwareIconSrc}
        alt={props.softwareName}
        className="size-6"
        loading="lazy"
      />
      <span className="cursor-pointer underline">
        {props.text || `${props.softwareName} guide`}
      </span>
    </Anchor>
  );
}
