import { faMemo } from "@awesome.me/kit-af809b8b43/icons/classic/solid";
import { Anchor } from "@flpstudio/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clsx } from "clsx/lite";
import type { ReactNode } from "react";
import { Link, generatePath } from "react-router";

import { paths } from "~/paths";

type Props = {
  className?: string;
  urlSlug: string;
  topicTitle: string;
  /**
   * If text needs to be customized, pass it here
   */
  text?: ReactNode;
  onClick?: () => void;
};

export function PublishedDocumentTitleLinkITBestPractice(props: Props) {
  return (
    <Anchor
      component={Link}
      to={generatePath(paths.documentation.viewer, {
        urlSlug: props.urlSlug,
      })}
      onClick={props.onClick}
      className={clsx("flex items-center gap-2 text-inherit", props.className)}
    >
      <FontAwesomeIcon
        icon={faMemo}
        className="size-6 text-[--mantine-primary-color-filled]"
      />
      <span className="cursor-pointer underline">
        {props.text || props.topicTitle}
      </span>
    </Anchor>
  );
}
