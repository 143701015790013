import { Navigate, Outlet, createSearchParams } from "react-router";

import { NavigationBar } from "@/components/organisms/NavigationBar/NavigationBar";
import { ViewExpertProfileDialog } from "@/components/organisms/ViewExpertProfileDialog/ViewExpertProfileDialog";
import { useAuth } from "@/hooks/use-auth";
import { useSignUpSearchParams } from "@/hooks/use-page-search-params";
import { paths } from "~/paths";

type Props = {
  showNavigationBar?: boolean;
};

export function Authenticated({ showNavigationBar = true }: Props) {
  const { postSignUp } = useSignUpSearchParams();

  const { user, isLoadingUser } = useAuth();

  if (isLoadingUser) {
    return null;
  }

  const areAgreementsAccepted = !!user?.termsAcceptedAt;
  const isUserTypeKnown = user?.expertProfile || user?.clientProfile;

  let redirectTo = "";

  if (!user) {
    redirectTo = paths.signIn;
  } else if (!areAgreementsAccepted) {
    redirectTo = paths.usageAgreement;
  } else if (!isUserTypeKnown) {
    redirectTo = paths.userTypeSelection;
  }

  return (
    <div>
      {showNavigationBar ? <NavigationBar /> : null}
      <Outlet />
      {redirectTo ? (
        <Navigate
          to={{
            pathname: redirectTo,
            search: createSearchParams(
              postSignUp && { postSignUp: postSignUp },
            ).toString(),
          }}
          replace
        />
      ) : (
        <ViewExpertProfileDialog />
      )}
    </div>
  );
}
