import { Divider, Grid, Paper, Stack, Tabs } from "@flpstudio/design-system";
import { useLocation, useMatch, useNavigate } from "react-router";

import { CreateDocumentForm } from "@/components/organisms/CreateDocumentForm/CreateDocumentForm";
import { useScreenSize } from "@/hooks/use-screen-size";

import { paths } from "~/paths";
import { ExpertDocuments } from "./ExpertDocuments";
import { ExpertIndustry } from "./ExpertIndustry";
import { ExpertIntroVideo } from "./ExpertIntroVideo";
import { ExpertPreferences } from "./ExpertPreferences";
import { ExpertProfile } from "./ExpertProfile";
import { ExpertSoftware } from "./ExpertSoftware";
import { ExpertTechnicalAreas } from "./ExpertTechnicalAreas";

export function EditProfile() {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLg } = useScreenSize();

  const isDocumentTabActive = useMatch(`${paths.profile.documents.root}/*`);

  const pathToItProfile = paths.profile.root;
  const pathToDocuments = paths.profile.documents.root;

  return (
    <Grid>
      <Grid.Col span={{ lg: location.pathname === pathToItProfile ? 12 : 8 }}>
        <Paper className="-mx-6 pt-0 lg:mx-0">
          <Tabs
            withUnderline={false}
            keepMounted={false}
            value={isDocumentTabActive ? pathToDocuments : location.pathname}
            onChange={(pathname) => navigate(pathname as string)}
            className="flex flex-col"
          >
            <Tabs.List component="nav" className="my-2">
              <Tabs.Tab value={pathToItProfile} className="border-none">
                IT profile
              </Tabs.Tab>
              <Tabs.Tab value={pathToDocuments} className="border-none">
                Guides written
              </Tabs.Tab>
            </Tabs.List>
            <Divider className="-mx-6 mb-6" />
            <Tabs.Panel value={pathToItProfile}>
              <ExpertProfile />
              <Divider className="-mx-6 my-6" />
              <ExpertIntroVideo />
              <Divider className="-mx-6 my-6" />
              <ExpertSoftware />
              <Divider className="-mx-6 my-6" />
              <ExpertTechnicalAreas />
              <Divider className="-mx-6 my-6" />
              <ExpertIndustry />
              <Divider className="-mx-6 my-6" />
              <ExpertPreferences />
            </Tabs.Panel>
            <Tabs.Panel value={pathToDocuments}>
              <Stack>
                {isDocumentTabActive && !isLg && <CreateDocumentForm />}
                <ExpertDocuments />
              </Stack>
            </Tabs.Panel>
          </Tabs>
        </Paper>
      </Grid.Col>
      {isDocumentTabActive && isLg && (
        <Grid.Col span={{ lg: 4 }}>
          <Paper component="aside" className="-mx-6 lg:mx-0">
            <CreateDocumentForm />
          </Paper>
        </Grid.Col>
      )}
    </Grid>
  );
}
