import { faChevronsRight } from "@awesome.me/kit-af809b8b43/icons/classic/regular";
import { Button, Group, Stack } from "@flpstudio/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, type To } from "react-router";

import { Skeleton } from "@/components/atoms/Loading/Skeleton";
import { Drawer } from "@/components/molecules/Drawer/Drawer";
import { NonPublishedDocument } from "@/components/organisms/Document/NonPublishedDocument";
import { ComponentError } from "@/components/organisms/Error/ComponentError";
import { useAuth } from "@/hooks/use-auth";
import { useDocument } from "@/hooks/use-documents";
import type { SDocumentStatus } from "types";

type Props = {
  opened: boolean;
  onClose: () => void;
  onDecline: (id: string) => void;
  onPublish: (id: string) => void;
  editUrl: To;
  id: string;
  status: SDocumentStatus | string;
};

export function ViewNonPublishedDocumentDialog(props: Props) {
  const { role } = useAuth();

  const { data, isLoading, isError } = useDocument(props.id);

  return (
    <Drawer opened={props.opened} onClose={props.onClose}>
      {props.id && props.status && (
        <Stack className="mt-6">
          <Group className="gap-2">
            <Button
              variant="outline"
              aria-label="Close preview"
              className="h-fit border-none p-1 text-[--mantine-color-gray-7]"
              onClick={props.onClose}
            >
              <FontAwesomeIcon icon={faChevronsRight} />
            </Button>
            {role === "admin" && props.status === "IN_REVIEW" && (
              <>
                <Button
                  variant="outline"
                  color="red"
                  className="ml-auto"
                  onClick={() => props.onDecline(props.id)}
                >
                  Decline
                </Button>
                <Button variant="outline" component={Link} to={props.editUrl}>
                  Edit
                </Button>
                <Button onClick={() => props.onPublish(props.id)}>
                  Publish
                </Button>
              </>
            )}
          </Group>
          {isLoading && <Skeleton repeat={5} />}
          {isError && <ComponentError />}
          {data && <NonPublishedDocument document={data} />}
        </Stack>
      )}
    </Drawer>
  );
}
