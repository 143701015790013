import { useMutation } from "@tanstack/react-query";

import { httpClient } from "@/api/client";
import type { StripeAddPaymentReqDto } from "@/api/openapi";

export function useStripePaymentCreate() {
  return useMutation({
    mutationFn: async (variables: StripeAddPaymentReqDto) => {
      const { data } = await httpClient.paths["/v1/payment/stripe"].post(
        null,
        variables,
      );
      return data;
    },
  });
}
