import { useState } from "react";

import { Skeleton } from "@/components/atoms/Loading/Skeleton";
import { useSoftware } from "@/hooks/use-software";
import {
  Button,
  SimpleGrid,
  Stack,
  Text,
  UnstyledButton,
} from "@flpstudio/design-system";
import { Link, generatePath } from "react-router";
import { paths } from "~/paths";

export const EXPAND_LIMIT = 12;

type SoftwareGridProps = {
  softwares: {
    id: string;
    name: string;
    icon: string;
    website?: string;
  }[];
  cols?: number;
};

export function SoftwareGrid({ softwares, cols = 3 }: SoftwareGridProps) {
  const [showAll, setShowAll] = useState(softwares.length <= EXPAND_LIMIT);
  const { data: softwareList, isLoading } = useSoftware({
    includeDocuments: true,
    softwareIds: softwares.map(({ id }) => id).join(","),
  });

  const softwaresToShow = showAll
    ? softwares
    : softwares.slice(0, EXPAND_LIMIT);

  if (isLoading) {
    return <Skeleton />;
  }

  const publishedDocUrlTitleMap = Object.fromEntries(
    (softwareList ?? [])
      .filter((software) => Boolean(software.documents?.[0]?.urlTitle))
      .map((software) => [
        software.id,
        generatePath(paths.documentation.viewer, {
          urlSlug: software.documents?.[0]?.urlTitle,
        }),
      ]),
  );

  return (
    <Stack>
      <SimpleGrid
        cols={{ base: 1, lg: cols }}
        component="ul"
        className="m-0 list-none p-0"
      >
        {softwaresToShow.map((software) => {
          const urlTitle = publishedDocUrlTitleMap[software.id];
          const link = urlTitle ? urlTitle : software.website;

          return (
            <li key={software.id}>
              <LinkWrapper internal={Boolean(urlTitle)} to={link}>
                <div className="flex h-10 items-center gap-2 rounded border border-[--mantine-color-gray-3] border-solid px-2">
                  <img
                    src={software.icon}
                    alt={software.name}
                    className="size-6"
                  />
                  <Text span lineClamp={1}>
                    {software.name}
                  </Text>
                </div>
              </LinkWrapper>
            </li>
          );
        })}
      </SimpleGrid>
      {!showAll && (
        <Button
          variant="transparent"
          className="self-start p-0 hover:underline"
          onClick={() => {
            setShowAll(true);
          }}
        >
          Expand all ({softwares.length})
        </Button>
      )}
    </Stack>
  );
}

type LinkWrapperProps = {
  children: React.ReactNode;
  internal: boolean;
  to?: URL | string;
};

function LinkWrapper({ children, internal, to }: LinkWrapperProps) {
  if (!to) {
    return children;
  }
  const externalLinkProps = !internal
    ? {
        rel: "noopener noreferrer nofollow",
        target: "_blank",
      }
    : {
        target: "_self",
      };

  return (
    <UnstyledButton component={Link} to={to} {...externalLinkProps}>
      {children}
    </UnstyledButton>
  );
}
