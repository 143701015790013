import { Anchor, Button, Modal, Text, Title } from "@flpstudio/design-system";
import { useDisclosure } from "@mantine/hooks";

import { WistiaVideoPlayer } from "@/components/molecules/VideoPlayer/VideoPlayer";
import { useAuth } from "@/hooks/use-auth";
import { useProfilePageSearchParams } from "@/hooks/use-page-search-params";
import { paths } from "~/paths";
import { ExpertIntroVideoForm } from "../ExpertIntroVideoForm/ExpertIntroVideoForm";

export function ExpertIntroVideo() {
  const { user } = useAuth();
  const { isModalOpen } = useProfilePageSearchParams();
  const [opened, handlers] = useDisclosure(isModalOpen("introVideo"));

  if (!user) return null;

  return (
    <div className="flex justify-between">
      <div className="flex flex-col gap-6">
        <Title order={1} className="text-xl">
          Video introduction
        </Title>
        {user.expertProfile?.profileVideoUrl ? (
          <WistiaVideoPlayer
            errorMsg="We are unable to find your video. Please check the link."
            url={user.expertProfile?.profileVideoUrl}
          />
        ) : (
          <Text>
            Introduce yourself to potential clients in a 1-3 minute video. We
            suggest speaking briefly about your experience and the type of
            support you can offer clients. Please create or upload your video in
            Wistia, following the instructions{" "}
            <Anchor
              href={paths.external.howToCreateWistiaVideo}
              target="_blank"
              rel="noreferrer noopener"
            >
              here
            </Anchor>
            .
          </Text>
        )}
      </div>
      <Button
        className="min-w-fit"
        variant="outline"
        onClick={() => {
          handlers.open();
        }}
      >
        Edit
      </Button>
      <Modal
        opened={opened}
        onClose={handlers.close}
        title="Video introduction"
      >
        <ExpertIntroVideoForm onClose={handlers.close} />
      </Modal>
    </div>
  );
}
