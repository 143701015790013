import {
  faCalendar,
  faGlobe,
} from "@awesome.me/kit-af809b8b43/icons/classic/regular";
import { faCircleCheck } from "@awesome.me/kit-af809b8b43/icons/classic/solid";
import {
  Button,
  Divider,
  Flex,
  Modal,
  Stack,
  Text,
} from "@flpstudio/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatInTimeZone } from "date-fns-tz";
import { useEffect, useState } from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";

import { Skeleton } from "@/components/atoms/Loading/Skeleton";
import { ComponentError } from "@/components/organisms/Error/ComponentError";
import { UserProfile } from "@/components/organisms/UserProfile/UserProfile";
import { useBookingList, useNewBookedSession } from "@/hooks/use-booking";
import {
  useExpert,
  useExpertDocuments,
  useExpertSoftwareSelection,
} from "@/hooks/use-expert";
import {
  Amplitude,
  SessionBookedEvent,
  buildExpertProperties,
} from "@/third-party/amplitude";

export type ScheduledEvent = {
  event: "calendly.event_scheduled";
  payload: {
    event: {
      uri: string;
    };
    invitee: {
      uri: string;
    };
  };
};

function CalendlyWidget(props: {
  calendlySchedulingURL: string;
  onEventScheduled: (newEvent: ScheduledEvent) => void;
}) {
  /**
   * Calendly does not emit events when rescheduling or canceling a session.
   * We can only rely on the `onEventScheduled` event to confirm a new booking.
   */
  useCalendlyEventListener({
    onEventScheduled: (event) => props.onEventScheduled(event.data),
  });

  return (
    <InlineWidget
      url={props.calendlySchedulingURL}
      iframeTitle="Book a session using Calendly"
      styles={{ height: "600px", marginInline: "-24px" }}
      pageSettings={{
        primaryColor: "#7950f2", // GuideStack purple
        textColor: "#1D293A",
        backgroundColor: "transparent",
        hideEventTypeDetails: true,
        hideLandingPageDetails: true,
      }}
    />
  );
}

// Display emails in a human-friendly way
function displayEmails(emails: string[]) {
  // email@domain.com
  if (emails.length === 1) {
    return `${emails[0]}.`;
  }

  // email1@domain.com, and email2@domain.com
  if (emails.length === 2) {
    return `${emails[0]} and ${emails[1]}.`;
  }

  // email1@domain.com, email2@domain.com, ..., and @emailN@domain.com
  return `${emails.slice(0, -1).join(", ")}, and ${emails.slice(-1)[0]}.`;
}

function SessionDetails(props: {
  expertIdentifier: string;
  calendlyEventUri: string;
  calendlyInviteeUri: string;
  onSettle: () => void;
  bookingType: "INTRO_BOOKING" | "ADVISORY_BOOKING";
  isVoucherCodeUsed?: boolean;
}) {
  const expert = useExpert({ identifier: props.expertIdentifier });
  const expertDocuments = useExpertDocuments({
    identifier: props.expertIdentifier,
  });
  const expertSoftwareSelection = useExpertSoftwareSelection({
    identifier: props.expertIdentifier,
  });

  useEffect(() => {
    if (expert.data && expertDocuments.data && expertSoftwareSelection.data) {
      Amplitude.track(SessionBookedEvent.name, {
        ...buildExpertProperties(expert.data),
        expert_industry_expertise: expert.data.industries,
        expert_published_document_count: expertDocuments.data.length,
        expert_software_expertise: expertSoftwareSelection.data.map(
          (s) => s.name,
        ),
        voucher_code_used: props.isVoucherCodeUsed ? "True" : "False",
        session_type:
          props.bookingType === "INTRO_BOOKING" ? "Intro" : "Standard",
      });
    }
  }, [
    expert.data,
    expertDocuments.data,
    expertSoftwareSelection.data,
    props.isVoucherCodeUsed,
    props.bookingType,
  ]);

  // Relies on Calendly
  const newBookedSession = useNewBookedSession(
    expert.data && {
      expertId: expert.data.id,
      inviteeUri: props.calendlyInviteeUri,
      eventUri: props.calendlyEventUri,
    },
  );

  // Refresh the booking list to show the new session
  useBookingList({ enabled: newBookedSession.isSuccess });

  useEffect(() => {
    if (!newBookedSession.isLoading) {
      props.onSettle();
    }
  }, [newBookedSession.isLoading, props.onSettle]);

  if (newBookedSession.isLoading || expert.isLoading) {
    return <Skeleton />;
  }

  if (newBookedSession.isError || expert.isError) {
    return <ComponentError />;
  }

  if (!newBookedSession.data) {
    return null;
  }

  const messageToExpert =
    newBookedSession.data?.additionalInfo?.questionAndAnswers[0]?.answer;

  return (
    <>
      <Text>
        We sent a calendar invite to{" "}
        {displayEmails([
          newBookedSession.data.calendlyClientEmail,
          ...newBookedSession.data.calendlyGuestEmails,
        ])}
      </Text>
      <Stack className="gap-2 rounded border border-[--mantine-color-gray-3] border-solid p-4">
        <UserProfile user={expert.data || {}} showFullProfileOnClick={false} />
        <div className="flex text-[--mantine-color-gray-6]">
          <FontAwesomeIcon icon={faCalendar} className="mt-0.5 mr-2 size-5" />
          <span>
            {formatInTimeZone(
              new Date(newBookedSession.data.sessionStartTime),
              newBookedSession.data.inviteeTimezone,
              "HH:mm",
            )}{" "}
            -{" "}
            {formatInTimeZone(
              new Date(newBookedSession.data.sessionEndTime),
              newBookedSession.data.inviteeTimezone,
              "HH:mm, EEEE MMMM dd, yyyy",
            )}
          </span>
        </div>
        <div className="flex text-[--mantine-color-gray-6]">
          <FontAwesomeIcon icon={faGlobe} className="mt-0.5 mr-2 size-5" />
          <span>{newBookedSession.data.inviteeTimezone}</span>
        </div>
        <Divider />
        <Text className="font-medium">Message to expert</Text>
        <Text>{messageToExpert || "None"}</Text>
      </Stack>
    </>
  );
}

type DialogProps = {
  expertIdentifier: string;
  calendlySchedulingUrl: string;
  bookingType: "ADVISORY_BOOKING" | "INTRO_BOOKING";
  opened: boolean;
  onConfirm: () => void;
  onClose: () => void;
  isVoucherCodeUsed?: boolean;
};

export function CalendlyAddBookingDialog(props: DialogProps) {
  const [scheduledEvent, setScheduledEvent] = useState<ScheduledEvent | null>(
    null,
  );

  useEffect(() => {
    if (props.opened) {
      setScheduledEvent(null);
    }
  }, [props.opened]);

  return (
    <Modal
      title={
        scheduledEvent ? (
          <>
            <FontAwesomeIcon
              icon={faCircleCheck}
              className="mr-2 text-[--mantine-color-green-6]"
            />
            <Text span className="font-semibold text-xl/normal">
              Confirmed
            </Text>
          </>
        ) : (
          <Text span className="font-semibold text-xl/normal">
            {props.bookingType === "INTRO_BOOKING"
              ? "Book a free introductory session"
              : "Book a session"}
          </Text>
        )
      }
      opened={props.opened}
      onClose={props.onClose}
    >
      <Stack>
        {scheduledEvent ? (
          <SessionDetails
            expertIdentifier={props.expertIdentifier}
            calendlyEventUri={scheduledEvent.payload.event.uri}
            calendlyInviteeUri={scheduledEvent.payload.invitee.uri}
            onSettle={props.onConfirm}
            isVoucherCodeUsed={props.isVoucherCodeUsed}
            bookingType={props.bookingType}
          />
        ) : (
          <CalendlyWidget
            calendlySchedulingURL={props.calendlySchedulingUrl}
            onEventScheduled={(newEvent) => setScheduledEvent(newEvent)}
          />
        )}
        <Flex className="-mb-6 sticky bottom-0 z-1 flex-col bg-white pb-6 lg:flex-row-reverse">
          <Button variant="outline" onClick={props.onClose}>
            Close
          </Button>
        </Flex>
      </Stack>
    </Modal>
  );
}
