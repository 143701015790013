import {
  Button,
  Flex,
  Group,
  Modal,
  Stack,
  Text,
  Title,
} from "@flpstudio/design-system";
import { useDisclosure } from "@mantine/hooks";

import { ExpertPreferencesForm } from "@/components/organisms/ExpertPreferencesForm/ExpertPreferencesForm";
import { useAuth } from "@/hooks/use-auth";
import { Amplitude, ExpertOwnProfileEvent } from "@/third-party/amplitude";
import { CLIENT_MATURITY } from "@/utils/client-maturity";
import { CLIENT_SIZE } from "@/utils/client-size";

const ExpertPreferences = () => {
  const { user } = useAuth();
  const [opened, handlers] = useDisclosure();

  // Checking mandatory fields to determine if the profile is complete.
  const isComplete = Boolean(
    user?.expertProfile?.preferClientSizes?.length &&
      user?.expertProfile?.preferClientMaturities?.length,
  );

  const cancelUpdate = () => {
    handlers.close();
    Amplitude.track(
      ExpertOwnProfileEvent.name,
      ExpertOwnProfileEvent.properties.preferenceModalClose,
    );
  };

  return (
    <Stack>
      <Group className="justify-between">
        <Title order={2} className="text-xl">
          IT client preferences
        </Title>
        <Button
          variant="outline"
          onClick={() => {
            handlers.open();
            Amplitude.track(
              ExpertOwnProfileEvent.name,
              ExpertOwnProfileEvent.properties.editClientPreferences,
            );
          }}
        >
          Edit
        </Button>
      </Group>
      {isComplete && (
        <>
          <p className="my-0">
            <span>
              What size of client company are you most comfortable advising?
            </span>
            <br />
            <span className="text-[--mantine-color-gray-7]">
              {CLIENT_SIZE.filter((size) =>
                user?.expertProfile?.preferClientSizes?.includes(size.value),
              )
                .map((size) =>
                  size.value !== "CLIENT_SIZE_JUST_ME"
                    ? `${size.label} employees`
                    : "Single employee",
                )
                .join(", ")}
            </span>
          </p>
          <div>
            <p className="my-0">
              What stage of IT maturity are you able to support in client
              organizations?
            </p>
            <ul className="m-0 list-none p-0">
              {CLIENT_MATURITY.filter((maturity) =>
                user?.expertProfile?.preferClientMaturities?.includes(
                  maturity.value,
                ),
              ).map((maturity) => (
                <li
                  key={maturity.value}
                  className="text-[--mantine-color-gray-7]"
                >
                  {maturity.label}
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
      {!isComplete && (
        <Text className="text-[--mantine-color-gray-7]">
          Please add your IT client preferences. This information will help us
          match you with potential clients.
        </Text>
      )}
      <Modal
        opened={opened}
        onClose={cancelUpdate}
        title="IT client preferences"
      >
        <ExpertPreferencesForm
          actionButtonGroup={
            <Flex className="-mb-6 sticky bottom-0 z-10 flex-col bg-white pb-6 lg:flex-row-reverse">
              <Button type="submit">Submit</Button>
              <Button variant="outline" onClick={cancelUpdate}>
                Cancel
              </Button>
            </Flex>
          }
          onSubmit={handlers.close}
        />
      </Modal>
    </Stack>
  );
};

export { ExpertPreferences };
