import {
  Anchor,
  Button,
  Container,
  Paper,
  Stack,
  Text,
} from "@flpstudio/design-system";

import { Logo } from "@/components/atoms/Logo/Logo";
import { Screen } from "@/components/atoms/Screen/Screen";
import { useAuth } from "@/hooks/use-auth";
import { useUpdateUserMutation } from "@/hooks/use-user-mutation";
import { Amplitude, SignUpAndLoginEvent } from "@/third-party/amplitude";
import { paths } from "~/paths";
import * as styles from "./PostSSOAgreement.module.css";

const PostSSOAgreement = () => {
  const { user } = useAuth();
  const { mutate: updateUser } = useUpdateUserMutation();

  const isAgreementAccepted = user?.termsAcceptedAt;

  // Prevent users from accessing this screen if they have already accepted the agreement
  if (isAgreementAccepted) {
    return null;
  }

  const handleClick = () => {
    updateUser({ termsAcceptedAt: new Date().toISOString() });
    Amplitude.track(
      SignUpAndLoginEvent.name,
      SignUpAndLoginEvent.properties.completeSignup,
    );
  };

  return (
    <Screen>
      <Container>
        <header>
          <Logo />
        </header>
        <Stack component="main" className={styles.main}>
          <Paper className={styles.paper}>
            <Stack>
              <Text component="h1" className={styles.heading}>
                One more step to get started
              </Text>
              <Text className={styles.subText}>
                To finish signing up, you must agree to GuideStack's{" "}
                <Anchor
                  href={paths.external.tos}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Terms of Service
                </Anchor>
                , Fujitsu Launchpad's{" "}
                <Anchor
                  href={paths.external.fujitsuTos}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Terms of Service
                </Anchor>
                , and our{" "}
                <Anchor
                  href={paths.external.privacy}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Privacy Policy
                </Anchor>
                .
              </Text>
              <Button onClick={handleClick}>Agree and continue</Button>
            </Stack>
          </Paper>
        </Stack>
      </Container>
    </Screen>
  );
};

export { PostSSOAgreement };
