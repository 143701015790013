import {
  faArrowRightFromBracket,
  faCircleQuestion,
  faGear,
  faUser,
} from "@awesome.me/kit-af809b8b43/icons/classic/regular";
import {
  Anchor,
  Avatar,
  Button,
  Flex,
  Menu,
  Modal,
  Text,
  UnstyledButton,
} from "@flpstudio/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDisclosure } from "@mantine/hooks";
import { useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router";

import { useAuth } from "@/hooks/use-auth";
import { Amplitude, UserMenuEvent } from "@/third-party/amplitude";
import { signOut } from "@/utils/auth";
import { paths } from "~/paths";
import { ClientOnboardingCallout } from "./ClientOnboardingCallout";
import { ExpertOnboardingCallout } from "./ExpertOnboardingCallout";

type Props = {
  className?: string;
};

const MAIN_MENU_ITEM_CLASSNAME =
  "mb-2 h-10 px-6 first-of-type:rounded-t-none last-of-type:rounded-b-none";
const SECONDARY_MENU_ITEM_CLASSNAME =
  "px-6 py-0 text-[--mantine-color-gray-5] text-xs/normal first-of-type:rounded-t-none last-of-type:rounded-b-none hover:bg-transparent hover:underline";

function UserMenu(props: Props) {
  const { user, setIsSignedIn } = useAuth();
  const [signOutModalOpened, signOutModalHandlers] = useDisclosure();

  const queryClient = useQueryClient();

  function handleMenuClick(eventKey: keyof typeof UserMenuEvent.properties) {
    Amplitude.track(UserMenuEvent.name, UserMenuEvent.properties[eventKey]);
  }

  return (
    <>
      <Menu position="top-end">
        <Menu.Target>
          <UnstyledButton aria-label="User menu" className={props.className}>
            <Avatar
              src={user?.profileImage}
              alt="Profile"
              className="size-8 min-w-0"
            />
          </UnstyledButton>
        </Menu.Target>
        <Menu.Dropdown className="py-4">
          {user?.expertProfile && <ExpertOnboardingCallout />}
          {user?.clientProfile && <ClientOnboardingCallout />}
          <Menu.Item
            component={Link}
            to={paths.profile.root}
            className={MAIN_MENU_ITEM_CLASSNAME}
            onClick={() => handleMenuClick("clickProfile")}
          >
            <FontAwesomeIcon className="mr-2" icon={faUser} />
            <Text span>
              {user?.expertProfile ? "Profile & guides" : "My IT profile"}
            </Text>
          </Menu.Item>
          <Menu.Item
            component={Link}
            to={paths.account.root}
            className={MAIN_MENU_ITEM_CLASSNAME}
            onClick={() => handleMenuClick("clickProfile")}
          >
            <FontAwesomeIcon className="mr-2" icon={faGear} />
            <Text span>Account</Text>
          </Menu.Item>
          <Menu.Item
            component={Anchor}
            // @ts-ignore
            href={paths.external.help}
            onClick={() => handleMenuClick("clickContact")}
            target="_blank"
            rel="noopener noreferrer"
            className={MAIN_MENU_ITEM_CLASSNAME}
          >
            <FontAwesomeIcon className="mr-2" icon={faCircleQuestion} />
            <Text span>Contact us</Text>
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              signOutModalHandlers.open();
              handleMenuClick("clickSignOut");
            }}
            className={MAIN_MENU_ITEM_CLASSNAME}
          >
            <FontAwesomeIcon className="mr-2" icon={faArrowRightFromBracket} />
            <Text span>Sign out</Text>
          </Menu.Item>
          <Menu.Item
            component={Anchor}
            // @ts-ignore
            href={paths.external.tos}
            target="_blank"
            rel="noopener noreferrer"
            className={SECONDARY_MENU_ITEM_CLASSNAME}
            onClick={() => handleMenuClick("clickGuideStackToS")}
          >
            GuideStack Terms of Service
          </Menu.Item>
          <Menu.Item
            component={Anchor}
            // @ts-ignore
            href={paths.external.commercialTxnAct}
            target="_blank"
            rel="noopener noreferrer"
            className={SECONDARY_MENU_ITEM_CLASSNAME}
            onClick={() => handleMenuClick("clickCommercialTxnAct")}
          >
            Specified Commercial Transaction Act
          </Menu.Item>

          {user?.expertProfile && (
            <Menu.Item
              component={Anchor}
              // @ts-ignore
              href={paths.external.expertTos}
              target="_blank"
              rel="noopener noreferrer"
              className={SECONDARY_MENU_ITEM_CLASSNAME}
              onClick={() => handleMenuClick("clickExpertToS")}
            >
              Expert Terms of Service
            </Menu.Item>
          )}
          <Menu.Item
            component={Anchor}
            // @ts-ignore
            href={paths.external.fujitsuTos}
            target="_blank"
            rel="noopener noreferrer"
            className={SECONDARY_MENU_ITEM_CLASSNAME}
            onClick={() => handleMenuClick("clickFLPToS")}
          >
            Fujitsu Launchpad Terms of Service
          </Menu.Item>
          <Menu.Item
            component={Anchor}
            // @ts-ignore
            href={paths.external.privacy}
            target="_blank"
            rel="noopener noreferrer"
            className={SECONDARY_MENU_ITEM_CLASSNAME}
            onClick={() => handleMenuClick("clickPrivacyPolicy")}
          >
            Privacy Policy
          </Menu.Item>
          {user?.isAdmin && (
            <Menu.Item
              component={Anchor}
              // @ts-ignore
              href={paths.external.admin}
              target="_blank"
              rel="noopener noreferrer"
              className={SECONDARY_MENU_ITEM_CLASSNAME}
            >
              Admin Portal
            </Menu.Item>
          )}
        </Menu.Dropdown>
      </Menu>
      <Modal
        opened={signOutModalOpened}
        onClose={signOutModalHandlers.close}
        title="Sign out of GuideStack?"
      >
        <Text>You can sign in again anytime.</Text>
        <Flex className="mt-4 flex-col lg:flex-row-reverse">
          <Button
            onClick={() => {
              signOut(queryClient);
              setIsSignedIn(false);

              handleMenuClick("logoutDialogClickSignOut");
            }}
          >
            Sign out
          </Button>
          <Button
            variant="outline"
            onClick={() => {
              handleMenuClick("logoutDialogClickCancel");
              signOutModalHandlers.close();
            }}
          >
            Cancel
          </Button>
        </Flex>
      </Modal>
    </>
  );
}

export { UserMenu };
