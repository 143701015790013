import { Button } from "@flpstudio/design-system";
import { clsx } from "clsx/lite";
import { createPath, useLocation } from "react-router";

import {
  useAvailableAdvisorySessionBooking,
  useAvailableIntroBooking,
} from "@/hooks/use-booking";
import { useSignUpSearchParams } from "@/hooks/use-page-search-params";
import { formatMoney } from "@/utils/format-money";

type BookingType = "INTRO_BOOKING" | "ADVISORY_BOOKING";

type Props = {
  className?: string;
  expertIdentifier: string;
  onClick?: (bookingType: BookingType) => void;
};

export function BookingPlaceholder(props: Props) {
  const { openSignUpModal } = useSignUpSearchParams();
  const { pathname, search } = useLocation();

  // Introductory session booking is dependent on the availability of an advisory session booking
  const availableAdvisorySessionBooking = useAvailableAdvisorySessionBooking({
    identifier: props.expertIdentifier,
  });

  const availableIntroBooking = useAvailableIntroBooking({
    identifier: availableAdvisorySessionBooking.data
      ? props.expertIdentifier
      : "",
  });

  if (!availableAdvisorySessionBooking.data) {
    return null;
  }

  function handleClick(bookingType: BookingType) {
    props.onClick?.(bookingType);
    openSignUpModal({
      postSignUp: createPath({ pathname, search }).toString(),
    });
  }

  const { price, durationMinute } = availableAdvisorySessionBooking.data;

  return (
    <div className={clsx("flex items-stretch gap-2", props.className)}>
      {availableIntroBooking && (
        <Button
          variant="outline"
          className="h-auto"
          onClick={() => handleClick("INTRO_BOOKING")}
        >
          Book a <br /> free intro
        </Button>
      )}
      <Button
        className="h-fit px-4 py-2"
        classNames={{ label: "flex-col font-normal" }}
        onClick={() => handleClick("ADVISORY_BOOKING")}
      >
        <span>Book a session</span>
        <span className="text-xs/normal">
          {formatMoney({
            amount: price.amount,
            currency: price.currency,
            showDecimal: false,
          })}{" "}
          for {durationMinute} min
        </span>
      </Button>
    </div>
  );
}
