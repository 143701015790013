import ReactPlayer, { type ReactPlayerProps } from "react-player";

export function WistiaVideoPlayer(
  props: ReactPlayerProps & { errorMsg?: string; className?: string },
) {
  const { errorMsg, className, ...rest } = props;
  return (
    <div className={className}>
      <div className="peer has-[iframe]:hidden">
        <ReactPlayer
          width="160px"
          height="90px"
          controls
          config={{
            wistia: {
              options: {
                wistiaPopover: true,
                playerColor: "#6941C6",
              },
            },
          }}
          {...rest}
        />
      </div>
      <div className="hidden text-[color:--mantine-color-gray-5] peer-has-[iframe]:block">
        {errorMsg ?? "Media not found."}
      </div>
    </div>
  );
}
