import { clsx } from "clsx/lite";

import { useAuth } from "@/hooks/use-auth";
import { AdvisorySessionBooking } from "./AdvisorySession/AdvisorySessionBooking";
import { BookingPlaceholder } from "./BookingPlaceholder";
import { IntroductorySessionBooking } from "./IntroductorySession/IntroductorySessionBooking";

type BookingType = "INTRO_BOOKING" | "ADVISORY_BOOKING";

type Props = {
  className?: string;
  expertIdentifier: string;
  /**
   * Initiate booking on when `bookingSession` search param is present
   */
  initiateOnSearchParams?: boolean;
  /**
   * Display "rebook" button instead of the "booking" button
   */
  isRebooking?: boolean;
  onBookSessionClick?: (bookingType: BookingType) => void;
};

export function Booking(props: Props) {
  const { user } = useAuth();

  if (!user) {
    return (
      <BookingPlaceholder
        expertIdentifier={props.expertIdentifier}
        onClick={props.onBookSessionClick}
        className={props.className}
      />
    );
  }

  const isSelf = props.expertIdentifier === (user?.urlId || user?.id);

  // Bookings are not available when viewing own profile
  if (isSelf) {
    return null;
  }

  return (
    <div className={clsx("flex items-stretch gap-2", props.className)}>
      {!props.isRebooking && (
        <IntroductorySessionBooking
          expertIdentifier={props.expertIdentifier}
          onClick={() => props.onBookSessionClick?.("INTRO_BOOKING")}
        />
      )}
      <AdvisorySessionBooking
        expertIdentifier={props.expertIdentifier}
        initiateOnSearchParams={props.initiateOnSearchParams}
        isRebooking={props.isRebooking}
        onClick={() => props.onBookSessionClick?.("ADVISORY_BOOKING")}
      />
    </div>
  );
}
