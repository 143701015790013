import {
  Button,
  Group,
  SimpleGrid,
  Stack,
  Text,
  Title,
} from "@flpstudio/design-system";
import { useDisclosure } from "@mantine/hooks";

import { Drawer } from "@/components/molecules/Drawer/Drawer";
import { ExpertIndustryForm } from "@/components/organisms/ExpertIndustryForm/ExpertIndustryForm";
import { useExpertIndustries } from "@/hooks/use-expert";
import { useProfilePageSearchParams } from "@/hooks/use-page-search-params";
import { Amplitude, ExpertOwnProfileEvent } from "@/third-party/amplitude";

const ExpertIndustry = () => {
  const { data: industries, refetch } = useExpertIndustries();
  const { isModalOpen } = useProfilePageSearchParams();
  const [opened, handlers] = useDisclosure(isModalOpen("industry"));

  if (!industries) return null;

  const hasContents = !!industries.length;

  const onClose = () => {
    handlers.close();
    // TODO: A better way to handle this is to invalidate the query cache
    // when ExpertIndustryForm is submitted
    refetch();
  };

  return (
    <Stack>
      <Group className="justify-between">
        <Title order={2} className="text-xl">
          Industry expertise
        </Title>
        <Button
          variant="outline"
          onClick={() => {
            handlers.open();
            Amplitude.track(
              ExpertOwnProfileEvent.name,
              ExpertOwnProfileEvent.properties.editIndustries,
            );
          }}
        >
          Edit
        </Button>
      </Group>
      {hasContents && (
        <>
          <Text>
            You’ve added {industries.length} industr
            {industries.length > 1 ? "ies" : "y"} to your expertise.
          </Text>
          <SimpleGrid
            cols={{ base: 1, lg: 4 }}
            component="ul"
            className="m-0 list-none p-0"
          >
            {industries.map((industry) => (
              <li
                key={industry.id}
                className="flex h-10 items-center gap-2 rounded border border-[--mantine-color-gray-3] border-solid px-2"
              >
                <Text span lineClamp={1}>
                  {industry.name}
                </Text>
              </li>
            ))}
          </SimpleGrid>
        </>
      )}
      {!hasContents && (
        <Text className="text-[--mantine-color-gray-7]">
          Please add the industries you have expertise in. This information will
          help us request relevant documents and match you with potential
          clients.
        </Text>
      )}
      <Drawer
        opened={opened}
        onClose={onClose}
        title="Add your industry expertise"
      >
        <ExpertIndustryForm onClose={onClose} withStickyControls />
      </Drawer>
    </Stack>
  );
};

export { ExpertIndustry };
