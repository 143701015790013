import { Button, Flex, Stack, Text } from "@flpstudio/design-system";
import { Link } from "react-router";

import {
  Amplitude,
  ExpertDashboardCalloutsEvent,
} from "@/third-party/amplitude";
import { paths } from "~/paths";
import * as styles from "./ExpertIncompleteProfileSetup.module.css";

interface Props {
  className?: string;
  onCancel: () => void;
}

function ExpertIncompleteProfileSetup(props: Props) {
  return (
    <Stack>
      <Text>
        Before creating your first document, please take a few minutes to finish
        your profile setup.
      </Text>
      <Flex className={styles.formControls}>
        <Button
          component={Link}
          to={paths.onboarding.expert}
          onClick={() => {
            Amplitude.track(
              ExpertDashboardCalloutsEvent.name,
              ExpertDashboardCalloutsEvent.properties.profileModalStart,
            );
          }}
        >
          Start
        </Button>
        <Button
          variant="outline"
          onClick={() => {
            props.onCancel();
            Amplitude.track(
              ExpertDashboardCalloutsEvent.name,
              ExpertDashboardCalloutsEvent.properties.profileModalCancel,
            );
          }}
        >
          Cancel
        </Button>
      </Flex>
    </Stack>
  );
}

export { ExpertIncompleteProfileSetup };
