import { Container } from "@flpstudio/design-system";

import { EditProfile as EditExpertProfile } from "@/components/organisms/EditExpertProfile/Profile";
import { useAuth } from "@/hooks/use-auth";
import { Profile as ClientProfile } from "./client/Profile";

export function Profile() {
  const { user } = useAuth();

  return (
    <Container component="main">
      {user?.clientProfile && <ClientProfile />}
      {user?.expertProfile && <EditExpertProfile />}
    </Container>
  );
}
