import {
  Checkbox,
  Flex,
  SimpleGrid,
  Stack,
  Text,
} from "@flpstudio/design-system";
import { zodResolver } from "@hookform/resolvers/zod";
import { type SubmitHandler, useForm } from "react-hook-form";
import { z as validator } from "zod";

import { useAuth } from "@/hooks/use-auth";
import { useUpdateUserMutation } from "@/hooks/use-user-mutation";
import { Amplitude, ExpertOnboardingEvent } from "@/third-party/amplitude";
import { CLIENT_MATURITY } from "@/utils/client-maturity";
import { CLIENT_SIZE } from "@/utils/client-size";
import type { ClientMaturity, ClientSize } from "types";
import * as styles from "./ExpertPreferencesForm.module.css";

interface Props {
  onSubmit: () => void;
  actionButtonGroup: React.ReactNode;
}

const schema = validator.object({
  companySize: validator
    .array(validator.string())
    .min(1, "Please select at least one option to proceed"),
  clientMaturity: validator
    .array(validator.string())
    .min(1, "Please select at least one option to proceed"),
});

type FormSchema = validator.infer<typeof schema>;

const ExpertPreferencesForm = (props: Props) => {
  const { user } = useAuth();
  const { mutate: updateUser } = useUpdateUserMutation();
  const { formState, getValues, register, handleSubmit } = useForm<FormSchema>({
    defaultValues: {
      companySize: user?.expertProfile?.preferClientSizes || [],
      clientMaturity: user?.expertProfile?.preferClientMaturities || [],
    },
    reValidateMode: "onBlur",
    resolver: zodResolver(schema),
  });

  const saveData = () => {
    updateUser({
      clientProfile: undefined,
      expertProfile: {
        ...user?.expertProfile,
        profileVideoUrl: user?.expertProfile?.profileVideoUrl || undefined,
        preferClientSizes: getValues("companySize") as ClientSize[],
        preferClientMaturities: getValues("clientMaturity") as ClientMaturity[],
      },
    });
  };

  const onSubmit: SubmitHandler<FormSchema> = () => {
    saveData();
    props.onSubmit();

    Amplitude.track(
      ExpertOnboardingEvent.name,
      ExpertOnboardingEvent.properties.preferenceSubmit,
    );
  };

  return (
    <form name="expertPreferences" onSubmit={handleSubmit(onSubmit)}>
      <Stack>
        <Flex className={styles.formContent}>
          <Text className={styles.question}>
            What size of client company are you most comfortable advising?
            (Select all that apply)
          </Text>
          <SimpleGrid cols={{ base: 2, lg: 4 }}>
            {CLIENT_SIZE.map((size) => (
              <Checkbox
                key={size.value}
                label={size.expertLabel}
                value={size.value}
                {...register("companySize", {
                  // Immediately save the data when the option is changed
                  onChange: saveData,
                })}
              />
            ))}
          </SimpleGrid>
          {formState.errors.companySize && (
            <Text c="red">{formState.errors.companySize.message}</Text>
          )}
          <Text className={styles.question}>
            What stage of IT maturity are you able to support in client
            organizations? (Select all that apply)
          </Text>
          <Stack>
            {CLIENT_MATURITY.map((clientMaturity) => (
              <Checkbox
                key={clientMaturity.value}
                label={clientMaturity.label}
                value={clientMaturity.value}
                {...register("clientMaturity", {
                  // Immediately save the data when the option is changed
                  onChange: saveData,
                })}
              />
            ))}
          </Stack>
          {formState.errors.clientMaturity && (
            <Text c="red">{formState.errors.clientMaturity.message}</Text>
          )}
          {props.actionButtonGroup}
        </Flex>
      </Stack>
    </form>
  );
};

export { ExpertPreferencesForm };
