import { Container, Stack } from "@flpstudio/design-system";
import { Outlet } from "react-router";

import { Logo } from "@/components/atoms/Logo/Logo";
import { Screen } from "@/components/atoms/Screen/Screen";

const Auth = () => {
  return (
    <Screen>
      <Container>
        <header>
          <Logo />
        </header>
        <Stack
          component="main"
          className="-mx-6 mt-6 mb-0 items-center lg:m-0 lg:mt-28"
        >
          <Outlet />
        </Stack>
      </Container>
    </Screen>
  );
};

export { Auth };
