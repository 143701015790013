import {
  Anchor,
  Container,
  Divider,
  Paper,
  Stack,
  Tabs,
  Text,
} from "@flpstudio/design-system";
import { Link, Navigate } from "react-router";

import { useAuth } from "@/hooks/use-auth";
import { paths } from "~/paths";

export default function Component() {
  const { user } = useAuth();
  if (user) {
    return <Navigate to={paths.sessions.root} />;
  }

  return (
    <Container component="main">
      <Stack>
        <Text>View and manage your advisory sessions and expert notes.</Text>
        <Paper className="-mx-6 pt-0 lg:mx-0">
          <Tabs withUnderline={false} defaultValue="upcoming">
            <Tabs.List component="nav" className="my-2">
              <Tabs.Tab value="upcoming" className="border-none">
                Upcoming
              </Tabs.Tab>
              <Tabs.Tab value="completed" className="border-none">
                Completed
              </Tabs.Tab>
            </Tabs.List>
            <Divider className="-mx-6 mt-2 mb-6" />
            <Tabs.Panel value="upcoming">
              <Text className="text-[--mantine-color-gray-6] text-sm/normal">
                You don’t have any upcoming sessions. Check out the{" "}
                <Anchor component={Link} to={paths.experts.root} inherit>
                  GuideStack expert directory
                </Anchor>{" "}
              </Text>
            </Tabs.Panel>
            <Tabs.Panel value="completed">
              <Text className="text-[--mantine-color-gray-6] text-sm/normal">
                You don’t have any completed sessions yet
              </Text>
            </Tabs.Panel>
          </Tabs>
        </Paper>
      </Stack>
    </Container>
  );
}
