import { useMutation } from "@tanstack/react-query";

import { httpClient } from "@/api/client";

type UseExpertTechnicalAreasMutationVariables = {
  action: "put" | "delete";
  technicalAreaId: string;
};

export function useExpertTechnicalAreasMutation() {
  return useMutation({
    mutationFn: (variables: UseExpertTechnicalAreasMutationVariables) =>
      httpClient.paths["/v1/expert/technical-areas/{technicalAreaId}"][
        variables.action
      ]({ technicalAreaId: variables.technicalAreaId }),
  });
}
