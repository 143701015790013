import {
  Button,
  Flex,
  SimpleGrid,
  Stack,
  Text,
} from "@flpstudio/design-system";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { clsx } from "clsx/lite";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z as validator } from "zod";

import { CardCheckbox } from "@/components/molecules/CardCheckbox/CardCheckbox";
import { Amplitude, ExpertOwnProfileEvent } from "@/third-party/amplitude";
import type { Industry } from "types";
import * as styles from "./ExpertIndustryForm.module.css";

interface Props {
  className?: string;
  onClose: () => void;
  /**
   * Whether the form should have sticky controls at the bottom
   */
  withStickyControls?: boolean;
}

const MAX_INDUSTRIES = 5;

const schema = validator.object({
  expertIndustries: validator.array(validator.string()),
});

type FormSchema = validator.infer<typeof schema>;

const ExpertIndustryForm = (props: Props) => {
  const [industries, setIndustries] = useState<Industry[]>([]);
  const { register, setValue, watch } = useForm<FormSchema>({
    resolver: zodResolver(schema),
  });

  const getLists = useCallback(() => {
    Promise.all([
      axios.get<Industry[]>("/industries?forExpert=true"),
      axios.get<Industry[]>("/expert/industries"),
    ]).then((list) => {
      setIndustries(list[0].data);
      setValue(
        "expertIndustries",
        list[1].data.map((industry) => industry.id),
      );
    });
  }, [setValue]);

  useEffect(getLists, []);

  const industriesSelected = watch("expertIndustries");

  const toggleOption = async (params: { selected: boolean; id: string }) => {
    const request = params.selected ? axios.put : axios.delete;
    await request(`/expert/industries/${params.id}`);
  };

  return (
    <form name="expertIndustry" className={clsx(styles.form, props.className)}>
      <Stack className={styles.stack}>
        <Flex className={styles.formContent}>
          <Text className={styles.secondaryText}>
            Please select up to {MAX_INDUSTRIES} industries in which you have
            the most experience.
          </Text>
          <SimpleGrid
            cols={{ base: 1, lg: 2 }}
            component="ul"
            className="m-0 list-none p-0"
          >
            {industries.map((industry) => (
              <li key={industry.id}>
                <CardCheckbox
                  disabled={
                    industriesSelected.length >= MAX_INDUSTRIES &&
                    !industriesSelected.includes(industry.id)
                  }
                  label={industry.name}
                  value={industry.id}
                  {...register("expertIndustries", {
                    onChange(event) {
                      toggleOption({
                        selected: event.target.checked,
                        id: event.target.value,
                      });
                    },
                  })}
                />
              </li>
            ))}
          </SimpleGrid>
        </Flex>
        <Flex
          className={clsx(
            styles.formControls,
            props.withStickyControls && styles.withStickyControls,
          )}
        >
          <Button
            variant="outline"
            onClick={() => {
              props.onClose();
              Amplitude.track(
                ExpertOwnProfileEvent.name,
                ExpertOwnProfileEvent.properties.industriesModalClose,
              );
            }}
          >
            Close
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};

export { ExpertIndustryForm };
